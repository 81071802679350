import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Button, Layout, Table, Space, Select, AutoComplete, Spin } from 'antd';
import decode from 'jwt-decode';
import { getIdToken } from '../../utils/auth/html-gen-auth/cognito';
import {
  filterByUniqueExpressions,
  handleFilterBy
} from '../../utils/eblast/eblast-home-utils';
import { useFetchEblasts } from '../../hooks/useFetchEblast';
import './Eblast.scss';

const environment = process.env.REACT_APP_ENV;

const { Header, Content } = Layout;
const { Option } = Select;

const Eblast = () => {
  const [eblastData, setEblastData] = useState([]);
  const [selectValue, setSelectValue] = useState('');
  const [uniqueClients, setUniqueClients] = useState([]);
  const [uniqueCreatedBys, setUniqueCreatedBys] = useState([]);
  const [filteredUsers, setFilteredUsers] = useState([]);
  const [filteredClients, setFilteredClients] = useState([]);
  const [filteredTemplateNames, setFilteredTemplateNames] = useState([]);
  const [
    filteredTemplateNamesOptions,
    setFilteredTemplateNamesOptions // used for autocomplete input
  ] = useState([]);
  const [autoCompleteOptions, setAutoCompleteOptions] = useState([]);

  const { status, data } = useFetchEblasts();

  useEffect(() => {
    if (!data || status === 'loading') return;
    setEblastData(data);

    let token;

    if (environment !== 'test') {
      getIdToken().then((tkn) => {
        token = tkn;
        setFilteredUsers(
          handleFilterBy(decode(token).email.split('@')[0], data, 'Created By')
        );
      });
    } else {
      setFilteredUsers(handleFilterBy('hugo.meza', data, 'Created By'));
    }
    setFilteredClients(data);
    setFilteredTemplateNames(data);
    setFilteredTemplateNamesOptions(
      filterByUniqueExpressions('templateNameOptions', data)
    );
    setUniqueClients(filterByUniqueExpressions('dealer', data));
    setUniqueCreatedBys(filterByUniqueExpressions('createdBy', data));
  }, [data, status]);

  const columns = [
    {
      title: 'Dealer',
      key: 'dealer',
      render: (data) => {
        if (data.foodtownVendor) return `${data.dealer} ${data.foodtownVendor}`;
        return data.dealer;
      }
    },
    {
      title: 'Template',
      dataIndex: 'templateName',
      key: 'templateName'
    },
    {
      title: 'Created By',
      dataIndex: 'createdBy',
      key: 'createdBy',
      render: (name) => name.split('@')[0]
    },
    {
      title: 'Created On',
      dataIndex: 'createdOn',
      key: 'createdOn'
    },
    {
      title: 'Updated By',
      dataIndex: 'updatedBy',
      key: 'updatedBy',
      render: (name) => name && name.split('@')[0]
    },
    {
      title: 'Action',
      key: 'action',
      render: (data) => (
        <Space size="middle">
          <Link
            to={{
              pathname: '/eblast/edit',
              state: data
            }}
          >
            <Button data-cy={`edit_${data.id.substring(0, 3)}`}>Edit</Button>
          </Link>
          <Link
            to={{
              pathname: '/eblast/clone',
              state: data
            }}
          >
            <Button data-cy={`clone_${data.id.substring(0, 3)}`}>Clone</Button>
          </Link>
        </Space>
      )
    }
  ];

  const handleOnSelectorChange = (val, selectValue) => {
    if (selectValue === 'Created By') {
      setFilteredUsers(handleFilterBy(val, eblastData, selectValue));
    } else if (selectValue === 'Dealer') {
      setFilteredClients(handleFilterBy(val, eblastData, selectValue));
    }
  };

  const onSelect = (data) => {
    setFilteredTemplateNames(handleFilterBy(data, eblastData, 'Template Name'));
  };

  const onSearch = (searchText) => {
    const filteredSearch = filteredTemplateNamesOptions.filter((el) =>
      el.value.startsWith(searchText)
    );

    setAutoCompleteOptions(filteredSearch.length > 0 ? filteredSearch : []);
    if (searchText.length <= 0) {
      setFilteredTemplateNames(eblastData);
      setAutoCompleteOptions([]);
    }
  };

  return (
    <div className="eblast_page_container">
      <Layout>
        <Header className="header">Email Templates</Header>
        <Content>
          <div className="create_btn">
            <Link to="/eblast/create">
              <Button type="primary" size="large" data-cy="new_btn">
                New
              </Button>
            </Link>
          </div>
          <div className="filter_section">
            <div className="text">Filter By:</div>
            <Select
              style={{ width: '15%' }}
              onChange={(val) => setSelectValue(val)}
              defaultValue="Show All"
              className="filter_selector"
            >
              <Option value="">Show All</Option>
              <Option value="Created By">Created By</Option>
              <Option value="Dealer">Dealer</Option>
              <Option value="Template Name">Template Name</Option>
            </Select>
            {selectValue === 'Created By' && (
              <Select
                style={{
                  width: '15%'
                }}
                onChange={(val) => handleOnSelectorChange(val, selectValue)}
                defaultValue={
                  filteredUsers[0] && filteredUsers[0].createdBy.split('@')[0]
                }
              >
                {uniqueCreatedBys.map((val, idx) => (
                  <Option value={val} key={idx}>
                    {val}
                  </Option>
                ))}
              </Select>
            )}
            {selectValue === 'Dealer' && (
              <Select
                style={{
                  width: '15%'
                }}
                onChange={(val) => handleOnSelectorChange(val, selectValue)}
                defaultValue="All"
              >
                {uniqueClients.map((val, idx) => (
                  <Option value={val} key={idx}>
                    {val}
                  </Option>
                ))}
              </Select>
            )}
            {selectValue === 'Template Name' && (
              <AutoComplete
                options={autoCompleteOptions}
                placeholder="Template name"
                style={{ width: '30%' }}
                onSelect={onSelect}
                onSearch={onSearch}
              />
            )}
          </div>
          <Table
            columns={columns}
            dataSource={
              selectValue === 'Created By'
                ? filteredUsers
                : selectValue === 'Dealer'
                ? filteredClients
                : selectValue === 'Template Name'
                ? filteredTemplateNames
                : eblastData
            }
            loading={{
              indicator: <Spin size="large"></Spin>,
              spinning: status === 'loading'
            }}
          />
        </Content>
      </Layout>
    </div>
  );
};

export default Eblast;
