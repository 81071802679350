import {
  CognitoUserPool,
  CognitoUser,
  AuthenticationDetails
} from 'amazon-cognito-identity-js';
import Amplify, { Auth } from 'aws-amplify';

const POOL_DATA = {
  UserPoolId: process.env.REACT_APP_AMAZON_USER_POOL_ID_EXTERNAL,
  ClientId: process.env.REACT_APP_AMAZON_COGNITO_CLIENT_ID_EXTERNAL
};

Amplify.configure({
  Auth: {
    userPoolId: process.env.REACT_APP_AMAZON_USER_POOL_ID_EXTERNAL,
    userPoolWebClientId: process.env.REACT_APP_AMAZON_COGNITO_CLIENT_ID_EXTERNAL
  }
});

const userPool = new CognitoUserPool(POOL_DATA);

export const cognitoSignInPasswordless = async (email, callback) => {
  try {
    await Auth.signIn(email)
      .then((cognitoUser) => {
        // user session needs to be stored and hydrated later
        localStorage.setItem(
          'cognitoUser',
          JSON.stringify({ username: email, session: cognitoUser.Session })
        );
      })
      .then(() => callback.setNextChallenge(true));
  } catch (err) {} // do nothing, we dont want to say email is not in db
};

export const answerCustomChallenge = async (code, callback) => {
  const user = JSON.parse(localStorage.getItem('cognitoUser'));

  if (!user) {
    // let 5 seconds before failing auth
    setTimeout(() => {
      callback.setIsAuthLoading(false);
      callback.error("Hmm, something doesn't look right.");
      return;
    }, 5000);
  } else {
    // rehydrate the CognitoUser
    const authData = {
      Username: user.username
    };

    // eslint-disable-next-line
    const authDetails = new AuthenticationDetails(authData);

    const userData = {
      Username: user.username,
      Pool: userPool
    };

    const cognitoUser = new CognitoUser(userData);
    cognitoUser.Session = user.session;

    // rehydrating the user and sending the auth challenge answer directly will not
    // trigger a new email
    cognitoUser.setAuthenticationFlowType('CUSTOM_AUTH');

    cognitoUser.sendCustomChallengeAnswer(code, {
      async onSuccess(success) {
        try {
          await Auth.currentSession();
          callback.setIsAuthLoading(false);
          localStorage.removeItem('cognitoUser');
          callback.setAuthentication(true);
        } catch {
          callback.error("Hmm, something doesn't look right.");
          callback.setIsAuthLoading(false);
        }
      },
      onFailure(err) {
        callback.error("Hmm, something doesn't look right.");
        callback.setIsAuthLoading(false);
      }
    });
  }
};

export const cognitoSignUp = (credentials, callback) => {
  const authData = {
    Username: credentials.email,
    Password: credentials.verificationCode
  };

  const authDetails = new AuthenticationDetails(authData);
  const userData = {
    Username: credentials.email,
    Pool: userPool
  };
  const cognitoUser = new CognitoUser(userData);

  cognitoUser.authenticateUser(authDetails, {
    onSuccess(result) {},
    onFailure(err) {},
    newPasswordRequired(userAttributes, requiredAttributes) {
      delete userAttributes.email_verified;
      cognitoUser.completeNewPasswordChallenge(
        credentials.password,
        userAttributes,
        {
          onSuccess(result) {
            callback.setAuthentication(true);
          },
          onFailure(err) {
            callback.error(err.message);
          }
        }
      );
    }
  });
};

export const cognitoSignIn = (credentials, callback) => {
  const authData = {
    Username: credentials.email,
    Password: credentials.password
  };

  const authDetails = new AuthenticationDetails(authData);
  const userData = {
    Username: credentials.email,
    Pool: userPool
  };

  const cognitoUser = new CognitoUser(userData);

  cognitoUser.authenticateUser(authDetails, {
    onSuccess(result) {
      callback.setAuthentication(true);
    },
    onFailure(err) {
      console.log('err 1: ', err);
      callback.error(err.message);
    }
  });
};

export const getCurrentUser = () => {
  return userPool.getCurrentUser();
};

export const isUserAuthenticated = async (setAuthenticatedUser) => {
  const user = await getCurrentUser();
  if (!user) {
    if (process.env.REACT_APP_ENV === 'test') {
      setAuthenticatedUser(true);
    } else setAuthenticatedUser(false);
  } else {
    user.getSession((err, session) => {
      if (err) {
        setAuthenticatedUser(false);
      } else {
        if (session.isValid()) {
          setAuthenticatedUser(true);
        } else {
          setAuthenticatedUser(false);
        }
      }
    });
  }
  return;
};

export const cognitoSignOut = async (setAuthenticatedUser) => {
  try {
    getCurrentUser().signOut();
    setAuthenticatedUser(false);
  } catch (err) {
    setAuthenticatedUser(false);
  }
};

export const getIdToken = async (setAuthenticatedUser) => {
  const user = getCurrentUser();
  if (!user) {
    setAuthenticatedUser(false);
    return null;
  }
  return await user.getSession((err, session) => {
    if (err) {
      setAuthenticatedUser(false);
      return null;
    }
    return session.getIdToken().getJwtToken();
  });
};

export const cognitoChangePassword = async (
  oldPassword,
  newPassword,
  callback
) => {
  const cognitoUser = getCurrentUser();
  await cognitoUser.getSession((err, session) => {
    if (err) {
      callback.setError(
        'Hmm, something is not right. Please log out, and login to try again'
      );
    }
  });

  cognitoUser.changePassword(oldPassword, newPassword, (err, result) => {
    if (err) {
      callback.setError(err.message);
      callback.setIsSuccess(false);
      callback.setIsLoading(false);
      callback.setDisableBtn(false);
    }
    callback.setIsSuccess(true);
    callback.setIsLoading(false);
    callback.setDisableBtn(true);
  });
};

export const cognitoForgotPassword = (username, callback) => {
  const userData = {
    Username: username,
    Pool: userPool
  };

  const cognitoUser = new CognitoUser(userData);

  cognitoUser.forgotPassword({
    onSuccess(result) {
      callback.setDisplaySecondChallenge(true);
    },
    onFailure(err) {}
    // we dont want current user know that email is not in db
  });
};

export const cognitoConfirmPassword = async (
  username,
  verificationCode,
  newPassword,
  callback
) => {
  const userData = {
    Username: username,
    Pool: userPool
  };

  const cognitoUser = new CognitoUser(userData);

  cognitoUser.confirmPassword(verificationCode, newPassword, {
    onFailure(err) {
      callback.setError(err.message);
    },
    onSuccess() {
      callback.setIsResetSuccess(true);
    }
  });
};
