import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faArrowCircleUp,
  faArrowCircleDown,
  faTimesCircle
} from '@fortawesome/free-solid-svg-icons';
import { Input } from 'antd';
import './EblastImageRow.scss';
import * as actions from '../../reducers/actions';
import { uploadImage } from '../../utils/eblast/create-utils';

const { TextArea } = Input;

const EblastImageRow = ({ images, idx, dispatch, isEdit }) => {
  return (
    <div className="row__wrapper" data-cy={`row_uploader_${idx}`}>
      {!isEdit && (
        <div
          className="button__delete__row"
          data-cy={`delete_row_btn_${idx}`}
          onClick={(e) => {
            e.preventDefault();
            dispatch({ type: actions.DELETE_ROW, payload: idx });
          }}
        >
          <div className="button__delete__icon">
            <FontAwesomeIcon icon={faTimesCircle} size="2x" />
          </div>
        </div>
      )}

      {images.map((img, i) => (
        <React.Fragment key={`images_${idx}_${i}`}>
          <div className="img__wrapper">
            <input
              type="file"
              data-cy={`image_uploader_${idx}_${i}`}
              onChange={async (e) => {
                e.persist();
                uploadImage(e).then((img) => {
                  dispatch({
                    type: actions.UPLOAD_IMAGE,
                    payload: { rowIdx: idx, imgIdx: i, imgData: img }
                  });
                });
              }}
            />
            <img
              data-cy={`row_image_${idx}`}
              src={img.previewUrl || img.imageUrl}
              alt=""
            />
          </div>

          <div className="img__info">
            <label>Link To:</label>
            <TextArea
              rows="2"
              data-cy={`url_input_${idx}_${i}`}
              type="text"
              className="form-control"
              placeholder="Type url to link image to"
              name="imageLink"
              onChange={(e) => {
                e.persist();
                dispatch({
                  type: actions.UPDATE_IMAGE_DATA,
                  payload: {
                    field: 'link',
                    rowIdx: idx,
                    imgIdx: i,
                    value: e.target.value
                  }
                });
              }}
              value={img.link ? img.link : ''}
            />

            <label>Alt Text:</label>
            <TextArea
              rows="5"
              className="form-control"
              placeholder="image description"
              type="text"
              name="imageAltText"
              data-cy={`image_description_input_${idx}_${i}`}
              onChange={(e) => {
                e.persist();
                dispatch({
                  type: actions.UPDATE_IMAGE_DATA,
                  payload: {
                    field: 'altTxt',
                    rowIdx: idx,
                    imgIdx: i,
                    value: e.target.value
                  }
                });
              }}
              value={img.altTxt}
              required
            />
          </div>
        </React.Fragment>
      ))}

      {!isEdit && (
        <div className="button__sort">
          <div>
            <FontAwesomeIcon
              className="button__sort__icon"
              icon={faArrowCircleUp}
              size="2x"
              data-cy={`move_up_btn_${idx}`}
              onClick={(e) => {
                e.preventDefault();
                dispatch({
                  type: actions.MOVE_IMAGE_UP,
                  payload: idx
                });
              }}
            />
          </div>
          <div>
            <FontAwesomeIcon
              className="button__sort__icon"
              icon={faArrowCircleDown}
              size="2x"
              data-cy={`move_down_btn_${idx}`}
              onClick={(e) => {
                e.preventDefault();
                dispatch({
                  type: actions.MOVE_IMAGE_DOWN,
                  payload: idx
                });
              }}
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default EblastImageRow;
