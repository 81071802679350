import React, { useState, useEffect } from 'react';
import './App.css';

import ClientUtilsRoutes from './ClientUtilsRoutes';
import HtmlGenRoutes from './HtmlGenRoutes';

import { isUserAuthenticated as htmlGenAuth } from './utils/auth/html-gen-auth/cognito';
import { isUserAuthenticated as clientAuth } from './utils/auth/client-auth/cognito';

const isClient = process.env.REACT_APP_IS_CLIENT;

const App = () => {
  const [authenticatedUser, setAuthenticatedUser] = useState(false);
  const [isCheckingAuth, setIsCheckingAuth] = useState(true);

  useEffect(() => {
    const getAuthenticatedUser = async () => {
      if (isClient === 'true') {
        await clientAuth(setAuthenticatedUser);
        setIsCheckingAuth(false);
      } else {
        await htmlGenAuth(setAuthenticatedUser);
        setIsCheckingAuth(false);
      }
    };
    getAuthenticatedUser();
  }, []);

  return (
    <div className="App">
      {isCheckingAuth ? (
        <div>auth checking...</div>
      ) : isClient === 'true' ? (
        <ClientUtilsRoutes
          authenticatedUser={authenticatedUser}
          setAuthenticatedUser={setAuthenticatedUser}
        />
      ) : (
        <HtmlGenRoutes
          authenticatedUser={authenticatedUser}
          setAuthenticatedUser={setAuthenticatedUser}
        />
      )}
    </div>
  );
};

export default App;
