import React from 'react';
import { Spin, Space } from 'antd';

import './Loader.scss';

const Loader = () => {
  return (
    <div className="loader">
      <Space size="large">
        <Spin size="large" />
      </Space>
    </div>
  );
};

export default Loader;
