import React, { useState, useEffect } from 'react';
import { getIdToken } from '../../utils/auth/client-auth/cognito';
import decode from 'jwt-decode';
import { Form, Input, Button } from 'antd';
import { cognitoChangePassword } from '../../utils/auth/client-auth/cognito';
import './Profile.scss';

const Profile = () => {
  const [userAuthInfo, setUserAuthInfo] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const [error, setError] = useState('');
  const [disableBtn, setDisableBtn] = useState(false);

  useEffect(() => {
    const getUserToken = async () => {
      const token = await getIdToken();
      setUserAuthInfo(decode(token));
    };
    getUserToken();
  }, []);

  const callback = {
    setError,
    setIsSuccess,
    setIsLoading,
    setDisableBtn
  };

  const onFinish = ({ currPassword, password }) => {
    setIsLoading(true);
    setDisableBtn(true);
    cognitoChangePassword(currPassword, password, callback);
  };

  const layout = {
    labelCol: { span: 4 },
    wrapperCol: { span: 24 }
  };

  const tailLayout = {
    wrapperCol: { offset: 4, span: 24 }
  };

  const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[\^$*.\]{}?\-“!@#%&/,><:;|_~`])\S{8,99}$/;

  return (
    <div className="user_profile_container">
      <div className="profile_white_background">
        <h4>Profile Name</h4>
        <div className="profile_name">
          {userAuthInfo['custom:firstName']} {userAuthInfo['custom:lastName']}.
        </div>
        <div style={{ height: '20px' }} />
        <h4>Change Password</h4>
        <div className="change_password_form">
          <Form
            {...layout}
            name="basic"
            initialValues={{
              remember: true
            }}
            onFinish={onFinish}
          >
            <Form.Item
              label="Current Password"
              name="currPassword"
              rules={[
                {
                  required: true,
                  message: 'Current password required.'
                }
              ]}
            >
              <Input.Password />
            </Form.Item>
            <Form.Item
              label="Your New Password"
              name="password"
              hasFeedback
              rules={[
                {
                  required: true,
                  message: 'Please input your new password.'
                },
                ({ getFieldValue }) => ({
                  validator(rule, value) {
                    if (value.match(passwordRegex)) {
                      return Promise.resolve();
                    }
                    return Promise.reject("Password doesn't meet requirements");
                  }
                })
              ]}
            >
              <Input.Password />
            </Form.Item>
            <Form.Item
              name="confirm"
              label="Confirm Password"
              dependencies={['password']}
              hasFeedback
              rules={[
                {
                  required: true,
                  message: 'Please confirm your password!'
                },
                ({ getFieldValue }) => ({
                  validator(rule, value) {
                    if (!value || getFieldValue('password') === value) {
                      return Promise.resolve();
                    }
                    return Promise.reject(
                      'The two passwords that you entered do not match!'
                    );
                  }
                })
              ]}
            >
              <Input.Password />
            </Form.Item>
            <Form.Item {...tailLayout}>
              <Button
                type="primary"
                htmlType="submit"
                loading={isLoading}
                disabled={disableBtn}
              >
                {isLoading
                  ? 'changing password...'
                  : isSuccess
                  ? 'Password Changed'
                  : 'Submit'}
              </Button>
            </Form.Item>
          </Form>
          {error && <div className="error_msg">**{error}**</div>}
          <div>
            Your new password should have at least one special character, lower
            and upper case letters, and be at least 8 characters long.
          </div>
        </div>
      </div>
    </div>
  );
};

export default Profile;
