import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import CALogo from '../../images/ca_logo.png';
import { Form, Input, Button } from 'antd';
import { cognitoSignIn } from '../../utils/auth/client-auth/cognito';

import './SignInClient.scss';

const SignInClient = ({ setAuthenticatedUser }) => {
  const [error, setError] = useState('');

  const callback = {
    error: setError,
    setAuthentication: setAuthenticatedUser
  };

  const onFinish = ({ email, password }) => {
    cognitoSignIn({ email, password }, callback);
  };

  const layout = {
    labelCol: { span: 4 },
    wrapperCol: { span: 24 }
  };

  const tailLayout = {
    wrapperCol: { offset: 4, span: 24 }
  };

  return (
    <div className="sign_in_client_container">
      <div className="white_bg_container">
        <div className="ca_logo_container">
          <div className="image_wrapper">
            <img src={CALogo} alt="Constellation Agency Logo" />
          </div>
        </div>
        <div className="form_container">
          <Form
            {...layout}
            name="basic"
            initialValues={{
              remember: true
            }}
            onFinish={onFinish}
          >
            <Form.Item
              label="Email"
              name="email"
              rules={[
                {
                  required: true,
                  message: 'Please input your username!'
                }
              ]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              label="Password"
              name="password"
              rules={[
                {
                  required: true,
                  message: 'Please input your password!'
                }
              ]}
            >
              <Input.Password />
            </Form.Item>
            <Form.Item {...tailLayout}>
              <Button type="primary" htmlType="submit">
                Submit
              </Button>
              <Link to="/signin/passwordless">
                <Button style={{ marginLeft: '10px' }}>
                  Passwordless login
                </Button>
              </Link>
            </Form.Item>
          </Form>
          {error && <div className="error_msg">{error}</div>}
          <div className="first_time_msg">
            Forgot Password? Click <Link to="/forgot">here</Link> to reset
            password.
          </div>
        </div>
      </div>
    </div>
  );
};

export default SignInClient;
