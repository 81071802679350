import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import SignInClient from './pages/signin/SignInClient';
import SignInClientPasswordless from './pages/signin/SignInPasswordless';
import VerifyClient from './pages/verify/VerifyClient';
import Home from './pages/client-utils/Home';
import Profile from './pages/client-utils/Profile';
import Forgot from './pages/forgot/ForgotClient';

const ClientUtilsRoutes = ({
  authenticatedUser,
  setAuthenticatedUser,
  clientSignOut
}) => {
  return (
    <Switch>
      <Route
        exact
        path="/signin"
        render={() =>
          authenticatedUser ? (
            <Redirect to="/" />
          ) : (
            <SignInClient setAuthenticatedUser={setAuthenticatedUser} />
          )
        }
      />
      <Route
        exact
        path="/verify"
        render={() =>
          authenticatedUser ? (
            <Redirect to="/" />
          ) : (
            <VerifyClient setAuthenticatedUser={setAuthenticatedUser} />
          )
        }
      />
      <Route
        exact
        path="/signin"
        render={() =>
          authenticatedUser ? (
            <Redirect to="/" />
          ) : (
            <SignInClient setAuthenticatedUser={setAuthenticatedUser} />
          )
        }
      />
      <Route
        exact
        path="/signin/passwordless"
        render={() =>
          authenticatedUser ? (
            <Redirect to="/" />
          ) : (
            <SignInClientPasswordless
              setAuthenticatedUser={setAuthenticatedUser}
            />
          )
        }
      />
      <Route
        exact
        path="/"
        render={() =>
          authenticatedUser ? (
            <Home
              clientSignOut={clientSignOut}
              setAuthenticatedUser={setAuthenticatedUser}
            />
          ) : (
            <Redirect to="/signin" />
          )
        }
      />
      <Route
        exact
        path="/profile"
        render={() =>
          authenticatedUser ? <Profile /> : <Redirect to="/signin" />
        }
      />
      <Route
        exact
        path="/forgot"
        render={() => (authenticatedUser ? <Redirect to="/" /> : <Forgot />)}
      />
      <Route
        exact
        path="*"
        render={() =>
          authenticatedUser ? <Redirect to="/" /> : <Redirect to="/signin" />
        }
      />
    </Switch>
  );
};

export default ClientUtilsRoutes;
