import React, { useState, useEffect } from 'react';
import { Form, Input, Button, Select, Switch } from 'antd';
import { faUserLock } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { getIdToken } from '../../utils/auth/html-gen-auth/cognito';
import './Sftp.scss';

const awsClientApi = process.env.REACT_APP_AWS_CLIENT_API;

const { Option } = Select;

const Sftp = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [isFetchSuccess, setIsFetchSuccess] = useState(false);
  const [error, setError] = useState('');
  const [disableBtn, setDisableBtn] = useState(false);
  const [sftTableData, setSftpTableData] = useState([]);
  const [hasSelectedNewClient, setHasSelectedNewClient] = useState(false);

  useEffect(() => {
    const fetchSftpData = async () => {
      const token = await getIdToken();
      const res = await fetch(`${awsClientApi}/get-clients`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `${token}`
        }
      })
        .then((res) => res.json())
        .then((res) =>
          res.sort((a, b) =>
            a.clientName > b.clientName
              ? 1
              : b.clientName > a.clientName
              ? -1
              : 0
          )
        );

      setSftpTableData(res);
    };
    fetchSftpData();
  }, []);

  const findClientInfo = (s3Path) => {
    let client = {};
    sftTableData.forEach((item) => {
      if (item.s3BucketPath === s3Path) {
        client = {
          clientName: item.clientName,
          id: item.id
        };
      }
    });
    return client;
  };

  const onFinish = async ({
    username,
    firstName,
    lastName,
    s3BucketPath,
    newClient
  }) => {
    setDisableBtn(true);
    setIsLoading(true);
    const token = await getIdToken();
    let response = {};
    if (newClient) {
      // create the s3 bucket and put to db first
      response = await fetch(`${awsClientApi}/create-bucket`, {
        method: 'POST',
        body: JSON.stringify({ newClient }),
        headers: {
          'Content-Type': 'application/json',
          Authorization: `${token}`
        }
      }).then((res) => res.json());
    }
    // then create user with the new s3 path, or just create it if no new client
    await fetch(`${awsClientApi}/create-user`, {
      method: 'POST',
      body: JSON.stringify({
        username,
        firstName,
        lastName,
        s3BucketPath: response.s3BucketPath
          ? response.s3BucketPath
          : s3BucketPath,
        id: response.id ? response.id : findClientInfo(s3BucketPath).id,
        clientName: newClient
          ? newClient
          : findClientInfo(s3BucketPath).clientName
      }),
      headers: { 'Content-Type': 'application/json', Authorization: `${token}` }
    })
      .then((res) => {
        if (!res.ok) {
          throw Error(res.statusText);
        } else {
          return res;
        }
      })
      .then((res) => res.json())
      .then((res) => {
        if (res.message === 'success') {
          setIsFetchSuccess(true);
          setIsLoading(false);
        } else {
          setIsFetchSuccess(false);
          setIsLoading(false);
          setError(res.message);
          setDisableBtn(false);
        }
      })
      .catch((err) => {
        setIsFetchSuccess(false);
        setIsLoading(false);
        setError(`${err.message}, user may already exists`);
        setTimeout(() => {
          setError('');
        }, 5000);
        setDisableBtn(false);
      });
  };

  const layout = {
    labelCol: { span: 6 },
    wrapperCol: { span: 24 }
  };

  const tailLayout = {
    wrapperCol: { offset: 6, span: 24 }
  };

  return (
    <div className="sftp_home_container">
      <div className="icon">
        <FontAwesomeIcon icon={faUserLock} size="4x" />
      </div>
      <div className="sftp_info">Input SFTP user's information.</div>
      <div className="form_container">
        <Form
          {...layout}
          name="basic"
          initialValues={{
            remember: true
          }}
          onFinish={onFinish}
        >
          <Form.Item
            label="Email"
            name="username"
            rules={[
              {
                required: true,
                message: "Please input user's email address."
              }
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label="User's First Name"
            name="firstName"
            rules={[
              {
                required: true,
                message: "Please input user's first name."
              }
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label="User's Last Name"
            name="lastName"
            rules={[
              {
                required: true,
                message: "Please input user's email address."
              }
            ]}
          >
            <Input />
          </Form.Item>
          {!hasSelectedNewClient && (
            <Form.Item
              label="Select Client Name"
              name="s3BucketPath"
              rules={[
                {
                  required: true,
                  message: 'Please select client name.'
                }
              ]}
            >
              <Select placeholder="Select client's name" allowClear>
                {sftTableData.map((data, idx) => (
                  <Option value={data.s3BucketPath} key={`stp_options_${idx}`}>
                    {data.clientName}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          )}

          <Form.Item label="New Client?">
            <Switch onChange={(val) => setHasSelectedNewClient(val)} />
          </Form.Item>
          {hasSelectedNewClient && (
            <Form.Item
              label="New Client Name"
              name="newClient"
              rules={[
                {
                  required: true,
                  message: 'New client name required'
                }
              ]}
            >
              <Input />
            </Form.Item>
          )}

          <Form.Item {...tailLayout}>
            <Button
              type="primary"
              htmlType="submit"
              loading={isLoading}
              disabled={disableBtn}
            >
              {isLoading
                ? 'creating user...'
                : isFetchSuccess
                ? 'User Created'
                : 'Submit'}
            </Button>
          </Form.Item>
        </Form>
        {error && <div className="error_msg">**{error}**</div>}
      </div>
    </div>
  );
};

export default Sftp;
