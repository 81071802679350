import React, { useState, useEffect } from 'react';
import { Upload, message, Button, Collapse } from 'antd';
import { InboxOutlined } from '@ant-design/icons';
import { getIdToken } from '../../utils/auth/client-auth/cognito';
import { cognitoSignOut } from '../../utils/auth/client-auth/cognito';
import decode from 'jwt-decode';
import Menu from '../../components/shared/Menu';

import './Home.scss';

const { Dragger } = Upload;
const { Panel } = Collapse;
const awsClientApi = process.env.REACT_APP_AWS_CLIENT_API;

const Home = ({ setAuthenticatedUser }) => {
  const [userAuthInfo, setUserAuthInfo] = useState('');
  const [userToken, setUserToken] = useState();
  const [username, setUsername] = useState('');
  const [userPassword, setUserPassword] = useState('');
  const [clientRecordName, setClientRecordName] = useState('');

  useEffect(() => {
    const getUserToken = async () => {
      const token = await getIdToken();
      setUserAuthInfo(decode(token));
      setUserToken(token);
    };
    getUserToken();
  }, []);

  useEffect(() => {
    const fetchUserData = async () => {
      const token = await getIdToken();
      const info = decode(token);
      await fetch(
        `${awsClientApi}/get-user?email=${info.email}&s3BucketPath=${info['custom:s3Path']}`,
        {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `${token}`
          }
        }
      )
        .then((res) => {
          if (!res.ok) {
            throw Error(res.statusText);
          } else {
            return res;
          }
        })
        .then((res) => res.json())
        .then((data) => {
          setUsername(data.username);
          setUserPassword(data.password);
          setClientRecordName(data.clientRecordName);
        })
        .catch((err) => {
          console.log(err);
        });
    };
    fetchUserData();
    // eslint-disable-next-line
  }, []);

  const confirmUpload = (fileName, fileType) => {
    if (
      window.confirm(
        `Are you sure you want to upload ${fileName}, of type ${fileType}?`
      )
    )
      return true;
    return false;
  };

  const props = {
    name: 'file',
    multiple: true,
    onChange(info) {
      const { status } = info.file;
      if (status === 'done') {
        message.success(`${info.file.name} file uploaded successfully.`);
      } else if (status === 'error') {
        message.error(`${info.file.name} file upload failed.`);
      }
    },
    beforeUpload(file) {
      return confirmUpload(file.name, file.type);
    }
  };

  const uploadRequest = async ({ file, onSuccess, onError }) => {
    const fileType = file.type;
    const fileName = file.name;
    const s3Path = userAuthInfo['custom:s3Path'];

    const body = {
      fileType,
      fileName,
      s3Path
    };

    try {
      const signedUrlRequest = await fetch(`${awsClientApi}/signed-url`, {
        method: 'POST',
        body: JSON.stringify(body),
        headers: {
          'Content-Type': 'application/json',
          Authorization: `${userToken}`
        }
      }).then((res) => res.json());

      const { signedRequest } = signedUrlRequest;
      await fetch(signedRequest, {
        method: 'PUT',
        headers: {
          'Content-Type': file.type
        },
        body: file
      }).then(async () => {
        const emailBody = {
          s3Path,
          fileName,
          clientName: s3Path.substring(22, s3Path.length - 5)
        };

        const emailResponse = await fetch(
          `${awsClientApi}/email-confirmation`,
          {
            method: 'POST',
            body: JSON.stringify(emailBody),
            headers: {
              'Content-Type': 'application/json',
              Authorization: `${userToken}`
            }
          }
        ).then((res) => res.json());
        if (emailResponse.message === 'success') {
          onSuccess('ok');
          return;
        }
        onError('ok');
      });
    } catch {
      onError('ok');
    }
  };

  const menuContent = [
    {
      title: 'Settings',
      subItems: [
        {
          title: 'Profile',
          items: [
            {
              link: '/profile',
              title: 'Edit Profile'
            }
          ]
        }
      ]
    }
  ];

  return (
    <div className="client_home_page_container">
      <Menu menuContent={menuContent} />
      <div className="info_text">
        <div className="user_intro">
          Welcome, {userAuthInfo['custom:firstName']}{' '}
          {userAuthInfo['custom:lastName']}.
        </div>
        <div className="user_info">
          <i>
            Any type of file is supported as long as the file does not exceed 5
            MB.
          </i>
        </div>
      </div>
      <div className="uploader_area">
        <Dragger
          {...props}
          customRequest={uploadRequest}
          style={{ width: '100%' }}
        >
          <p className="ant-upload-drag-icon">
            <InboxOutlined />
          </p>
          <p className="ant-upload-text">
            Click or drag file to this area to upload
          </p>
          <p className="ant-upload-hint">
            Support for a single or bulk upload.
          </p>
        </Dragger>
      </div>
      <Collapse style={{ marginTop: '5%' }}>
        <Panel header="Advanced SFTP Info">
          <div className="user_sftp_information">
            <div className="title">Important Information</div>
            You must use an SFTP application such as FileZilla, Cyberduck, etc
            to access this space or share these files. You will need to accept
            the RSA fingerprint the first time you connect.
            <div className="title">Access Credentials</div>
            <div>Protocol: SFTP (Secure FTP)</div>
            <div>Host: {clientRecordName}.sftp.ext.constellationenv.com</div>
            <div>Port: 22</div>
            <div>User: {username}</div>
            <div>Pass: {userPassword}</div>
          </div>
        </Panel>
      </Collapse>
      <div className="btn_container">
        <Button
          type="primary"
          onClick={(e) => {
            e.preventDefault();
            cognitoSignOut(setAuthenticatedUser);
          }}
        >
          Log Out
        </Button>
      </div>
    </div>
  );
};

export default Home;
