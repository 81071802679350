import React, { useState } from 'react';
import { Link, Redirect } from 'react-router-dom';
import CALogo from '../../images/ca_logo.png';
import { Form, Input, Button } from 'antd';
import {
  cognitoForgotPassword,
  cognitoConfirmPassword
} from '../../utils/auth/client-auth/cognito';

import '../signin/SignInClient.scss';

const ForgotClient = () => {
  const [error, setError] = useState('');
  const [displaySecondChallenge, setDisplaySecondChallenge] = useState(false);
  const [isResetSuccess, setIsResetSuccess] = useState(false);
  const [isValidPassword, setIsValidPassword] = useState(false);

  const callback = {
    setError,
    setDisplaySecondChallenge,
    setIsResetSuccess
  };

  const onFinishFirstChallenge = ({ email }) => {
    cognitoForgotPassword(email, callback);
  };

  const onFinishSecondChallenge = ({ email, verificationCode, password }) => {
    cognitoConfirmPassword(email, verificationCode, password, callback);
  };

  const layout = {
    labelCol: { span: displaySecondChallenge ? 6 : 4 },
    wrapperCol: { span: 24 }
  };

  const tailLayout = {
    wrapperCol: { offset: displaySecondChallenge ? 6 : 4, span: 24 }
  };

  const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[\^$*.\]{}?\-“!@#%&/,><:;|_~`])\S{8,99}$/;

  return (
    <div className="sign_in_client_container">
      {isResetSuccess && <Redirect to="/signin" />}
      <div className="white_bg_container">
        <div className="ca_logo_container">
          <div className="image_wrapper">
            <img src={CALogo} alt="Constellation Agency Logo" />
          </div>
        </div>
        {displaySecondChallenge ? (
          <div className="first_time_msg">
            Input your verification code, and the new password.
            <br />
            Your new password should have at least one special character, lower
            and upper case letters, and be at least 8 characters long.
          </div>
        ) : (
          <div className="first_time_msg">
            If your email is in our databases, you will receive a verification
            code.
          </div>
        )}
        <div className="form_container">
          {displaySecondChallenge ? (
            <Form
              {...layout}
              name="basic"
              initialValues={{
                remember: true
              }}
              onFinish={onFinishSecondChallenge}
            >
              <Form.Item
                label="Confirm your email"
                name="email"
                rules={[
                  {
                    required: true,
                    message: 'Email required'
                  }
                ]}
              >
                <Input />
              </Form.Item>
              <Form.Item
                label="Verification Code"
                name="verificationCode"
                rules={[
                  {
                    required: true,
                    message: 'Please input your verification code.'
                  }
                ]}
              >
                <Input.Password />
              </Form.Item>
              <Form.Item
                label="Your New Password"
                name="password"
                hasFeedback
                rules={[
                  {
                    required: true,
                    message: 'Please input your new password.'
                  },
                  ({ getFieldValue }) => ({
                    validator(rule, value) {
                      if (value.match(passwordRegex)) {
                        return Promise.resolve();
                      }
                      setIsValidPassword(false);
                      return Promise.reject(
                        "Password doesn't meet requirements"
                      );
                    }
                  })
                ]}
              >
                <Input.Password />
              </Form.Item>
              <Form.Item
                name="confirm"
                label="Confirm Password"
                dependencies={['password']}
                hasFeedback
                rules={[
                  {
                    required: true,
                    message: 'Please confirm your password!'
                  },
                  ({ getFieldValue }) => ({
                    validator(rule, value) {
                      if (!value || getFieldValue('password') === value) {
                        setIsValidPassword(true);
                        return Promise.resolve();
                      }
                      setIsValidPassword(false);
                      return Promise.reject(
                        'The two passwords that you entered do not match!'
                      );
                    }
                  })
                ]}
              >
                <Input.Password />
              </Form.Item>
              <Form.Item {...tailLayout}>
                <Button
                  type="primary"
                  htmlType="submit"
                  disabled={!isValidPassword}
                >
                  Submit
                </Button>
              </Form.Item>
            </Form>
          ) : (
            <Form
              {...layout}
              name="basic"
              initialValues={{
                remember: true
              }}
              onFinish={onFinishFirstChallenge}
            >
              <Form.Item
                label="Email"
                name="email"
                rules={[
                  {
                    required: true,
                    message: 'Email required'
                  }
                ]}
              >
                <Input />
              </Form.Item>
              <Form.Item {...tailLayout}>
                <Button type="primary" htmlType="submit">
                  Submit
                </Button>
                <Link to="/signin">
                  <Button style={{ marginLeft: '10px' }}>
                    Back to sign in
                  </Button>
                </Link>
              </Form.Item>
            </Form>
          )}
          {error && <div className="error_msg">{error}</div>}
        </div>
      </div>
    </div>
  );
};

export default ForgotClient;
