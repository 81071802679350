import React, { useEffect, useReducer } from 'react';
import { renderToStaticMarkup } from 'react-dom/server';
import {
  htmlDisplayFormatter,
  htmlEblastFormatter
} from '../../utils/eblast/create-utils';
import Table from '../../components/eblast/Table';
import pretty from 'pretty';
import './Preview.scss';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCopy } from '@fortawesome/free-solid-svg-icons';
import { Link, useLocation, Redirect } from 'react-router-dom';
import { getIdToken } from '../../utils/auth/html-gen-auth/cognito';
import qs from 'qs';
import { Collapse, Layout, notification, Spin } from 'antd';
import { SmileOutlined, DislikeOutlined } from '@ant-design/icons';
import { initialStatePreview, reducer } from '../../reducers/reducer';
import * as actions from '../../reducers/actions';

const AWS_API = process.env.REACT_APP_AWS_API;

const { Panel } = Collapse;
const { Header, Content } = Layout;

const PreviewEblast = (props) => {
  const [state, dispatch] = useReducer(reducer, initialStatePreview);
  const queryId = qs.parse(useLocation().search, { ignoreQueryPrefix: true });

  useEffect(() => {
    window.scrollTo(0, 0);

    const fetchEblast = async (id) => {
      const eblast = await fetch(`${AWS_API}/get-eblast?id=${id}`).then((res) =>
        res.json()
      );
      if (!eblast) {
        dispatch({ type: actions.SET_SHOULD_REDIRECT });
      } else {
        dispatch({ type: actions.SET_PREVIEW_STATE, payload: eblast });
      }
    };

    if (props.location && props.location.state) {
      dispatch({
        type: actions.SET_PREVIEW_STATE,
        payload: props.location.state
      });
    } else if (queryId && queryId.id) {
      fetchEblast(queryId.id);
    } else {
      dispatch({ type: actions.SET_SHOULD_REDIRECT });
    }
    // eslint-disable-next-line
  }, []);

  const foodtownHtmlBeginning =
    '<!DOCTYPE html> <html lang="en"> <head> <meta charset="UTF-8"> <meta name="viewport" content="width=device-width, initial-scale=1.0"><title>Document</title><style> body {margin: 0 auto;} img {display: block;} #ios-added-space {display: none;} @media screen and (max-device-width: 375px) and (max-device-height: 812px) {#ios-added-space {display: block;}} @media screen and (max-device-width: 320px) and (max-device-height: 568px) {#ios-added-space {display: block;}} @media screen and (max-device-width: 375px) and (max-device-height: 667px) {#ios-added-space {display: block;}} @media screen and (max-device-width: 414px) and (max-device-height: 776px) {#ios-added-space {display: block;}} @media screen and (max-device-width: 777px) and (max-device-height: 1080px) {#ios-added-space {display: block;}} </style></head><body>';
  let htmlBegining =
    '<!DOCTYPE html> <html lang="en"> <head> <meta charset="UTF-8"> <meta name="viewport" content="width=device-width, initial-scale=1.0"><title>Document</title><style> body {margin: 0 auto;} img {display: block;} </style></head><body>';

  let htmlEnd = '</body> </html>';

  let stringifyComponent = renderToStaticMarkup(<Table parentState={state} />);

  const getFormattedHtmlText = (type) => {
    let value = '';
    if (type === 'eblast') {
      state.foodTownVendor
        ? (value = htmlEblastFormatter(
            pretty(foodtownHtmlBeginning + stringifyComponent + htmlEnd, {
              ocd: true
            })
          ))
        : (value = htmlEblastFormatter(
            pretty(htmlBegining + stringifyComponent + htmlEnd, { ocd: true })
          ));
    } else {
      state.foodTownVendor
        ? (value = htmlDisplayFormatter(
            pretty(foodtownHtmlBeginning + stringifyComponent + htmlEnd, {
              ocd: true
            })
          ))
        : (value = htmlDisplayFormatter(
            pretty(htmlBegining + stringifyComponent + htmlEnd, { ocd: true })
          ));
    }
    return value;
  };

  const sendEmailTest = async (event) => {
    event.preventDefault();
    dispatch({ type: actions.FETCHING_START });

    let body = {
      destinationEmail: state.email,
      stringHTML: getFormattedHtmlText('eblast')
    };

    const token = await getIdToken();
    try {
      await fetch(`${AWS_API}/send`, {
        method: 'POST',
        body: JSON.stringify(body),
        headers: {
          'Content-Type': 'application/json',
          Authorization: `${token}`
        }
      });
      notification.open({
        message: 'Success',
        description: 'Email sent',
        icon: <SmileOutlined style={{ color: '#108ee9' }} />
      });
      dispatch({ type: actions.SET_API_RESPONSE, payload: 'success' });
    } catch (err) {
      notification.open({
        message: 'Whoops..',
        description: 'Something went wrong. Please try again',
        icon: <DislikeOutlined style={{ color: '#108ee9' }} />
      });
    }
  };

  const htmlToBeDisplayed = getFormattedHtmlText('display');

  return (
    <div className="preview-page">
      {state.shouldRedirect && <Redirect to="/eblast" />}
      <Layout style={{ minHeight: '100vh' }}>
        <Header className="header">Email Preview</Header>
        <Content>
          <div className="container">
            <Collapse defaultActiveKey={['preview_panel_3']}>
              <Panel header="Shareable Preview Url" key="preview_panel_1">
                <div className="html_content_wrapper">
                  <div
                    className="html__formatted__code"
                    style={{ textAlign: 'center' }}
                  >
                    <div className="copy_wrapper">
                      <CopyToClipboard
                        text={`https://utils.constellationagency.com/eblast/preview?id=${state.id}`}
                        onCopy={() =>
                          dispatch({
                            type: actions.SET_COPIED_TEXT,
                            payload: 'copiedLink'
                          })
                        }
                      >
                        <div>
                          <FontAwesomeIcon icon={faCopy} size="2x" />
                          {state.copiedLink ? <span> Copied! </span> : null}
                        </div>
                      </CopyToClipboard>
                    </div>
                    {`https://utils.constellationagency.com/eblast/preview?id=${state.id}`}
                  </div>
                </div>
              </Panel>
              <Panel header="HTML Content" key="preview_panel_2">
                <div className="html_content_wrapper">
                  <div className="html__formatted__code">
                    <div className="copy_wrapper">
                      <CopyToClipboard
                        text={getFormattedHtmlText('eblast')}
                        onCopy={() =>
                          dispatch({
                            type: actions.SET_COPIED_TEXT,
                            payload: 'copiedHtml'
                          })
                        }
                      >
                        <div>
                          <FontAwesomeIcon icon={faCopy} size="2x" />
                          {state.copiedHtml ? <span> Copied! </span> : null}
                        </div>
                      </CopyToClipboard>
                    </div>
                    {htmlToBeDisplayed}
                  </div>
                </div>
              </Panel>
              <Panel header="Eblast Preview" key="preview_panel_3">
                <div className="container__table">
                  <div className="component__table">
                    <Table parentState={state} isPreview={true} />
                  </div>
                </div>
              </Panel>
            </Collapse>
            <div className="container__footer">
              <div className="button__send_email">
                {!state.requestEmail ? (
                  <button
                    type="submit"
                    onClick={(e) => {
                      e.preventDefault();
                      dispatch({ type: actions.SET_REQUEST_EMAIL });
                    }}
                    className="send_button"
                    style={{ marginTop: '2%' }}
                  >
                    Send email with eblast preview & code
                  </button>
                ) : !state.isFetching && !state.apiResponse ? (
                  <form onSubmit={sendEmailTest}>
                    <input
                      className="form-control"
                      placeholder="email"
                      type="email"
                      name="email"
                      onChange={(e) => {
                        e.preventDefault();
                        dispatch({
                          type: actions.SET_EMAIL,
                          payload: e.target.value
                        });
                      }}
                      value={state.email}
                      required
                    />
                    <button type="submit">Send</button>
                  </form>
                ) : (
                  state.isFetching &&
                  !state.apiResponse && <Spin size="large" />
                )}
                <div style={{ marginTop: '1%' }}>
                  <Link to="/eblast">
                    <button type="submit">Back to Eblast Dashboard</button>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </Content>
      </Layout>
    </div>
  );
};

export default PreviewEblast;
