import React from 'react';
import { Link } from 'react-router-dom';
import { Menu } from 'antd';
import { SettingOutlined } from '@ant-design/icons';

const { SubMenu } = Menu;

/**
 * Reference array of objects for menu
 * 
 * const menuContent = [
    {
      title: 'Main title' <string>,
      subItems: [
        {
          title: 'Subitem Title',
          items: [
            {
              link: '/example',
              title: 'Example'
            }
          ]
        }
      ]
    }
  ];
 */

const MenuComponent = ({ menuContent }) => {
  return (
    <Menu mode="horizontal">
      {menuContent.map((subMenu, idx) => (
        <SubMenu
          key={`sub_${idx}`}
          icon={<SettingOutlined />}
          title={subMenu.title}
        >
          {subMenu.subItems.map((subItem, idx) => (
            <Menu.ItemGroup title={subItem.title} key={`group_${idx}`}>
              {subItem.items.map((item, idx) => (
                <Menu.Item key={`item_${idx}`}>
                  {item.link ? (
                    <Link to={item.link}>{item.title}</Link>
                  ) : (
                    item.title
                  )}
                </Menu.Item>
              ))}
            </Menu.ItemGroup>
          ))}
        </SubMenu>
      ))}
    </Menu>
  );
};

export default MenuComponent;
