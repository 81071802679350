import React, { useState } from 'react';
import * as actions from '../../reducers/actions';
import { Select } from 'antd';
import { dealers, foodtown } from '../../dealerList.json';
import './DealerSelector.scss';

const { Option } = Select;

const DealerSelector = ({  
  parentState,
  dispatch
}) => {
  const sortedDealerList = dealers.sort((a, b) => {
    const aL = a.toLocaleLowerCase();
    const bL = b.toLocaleLowerCase();
    return aL > bL ? 1 : bL > aL ? -1 : 0;
  });
  const [dealerList, setDealerList] = useState(sortedDealerList);

  const onSearch = (val) => {
    if (parentState.isEdit) return;
    if (val === '') setDealerList(dealers);
    const filteredList = dealerList.filter((dealer) =>
      dealer.toLowerCase().includes(val.toLowerCase())
    );
    setDealerList(filteredList);
  };

  return (
    <div className="dealer-info">
      <Select
        name="dealers"
        onChange={(value) =>
          dispatch({ type: actions.UPDATE_DEALER, payload: value })
        }
        data-cy="dealer_selector"
        style={{ width: '30%' }}
        placeholder={!parentState.dealer && 'Select a dealer'}
        showSearch={!parentState.isEdit}
        onSearch={onSearch}
        allowClear={!parentState.isEdit}
        value={parentState.dealer ? parentState.dealer : undefined}
      >
        {dealers.map((dlr, idx) => (
          <Option
            value={dlr}
            key={idx}
            data-cy="dealer_options"
            disabled={parentState.isEdit && dlr !== parentState.dealer}
          >
            {dlr}
          </Option>
        ))}
      </Select>
      {parentState.dealer === 'Foodtown' ? (
        <Select
          name="vendors"
          onChange={(value) =>
            dispatch({ type: actions.UPDATE_FOODTOWN_VENDOR, payload: value })
          }
          data-cy="foodtown-vendor-selector"
          style={{ width: '30%', marginTop: '1%' }}
          placeholder="Select a Foodtown Vendor"
          value={
            parentState.foodtownVendor ? parentState.foodtownVendor : undefined
          }
        >
          vendor
          {foodtown.map((vendor, idx) => (
            <Option
              value={vendor}
              key={idx}
              disabled={
                parentState.isEdit && parentState.foodtownVendor !== vendor
              }
            >
              {vendor}
            </Option>
          ))}
        </Select>
      ) : null}
    </div>
  );
};

export default DealerSelector;
