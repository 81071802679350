import React, { useState, useEffect } from 'react';
import { useLocation, Redirect } from 'react-router-dom';
import qs from 'qs';
import CALogo from '../../images/ca_logo.png';
import { Form, Input, Button } from 'antd';
import { cognitoSignUp } from '../../utils/auth/client-auth/cognito';

import '../signin/SignInClient.scss'; // share styles

const VerifyClient = ({ setAuthenticatedUser }) => {
  const [error, setError] = useState('');
  const authParam = useLocation().search;
  const [email, setEmail] = useState('');
  const [shouldRedirect, setShouldRedirect] = useState(false);
  const [isValidPassword, setIsValidPassword] = useState(false);

  useEffect(() => {
    const saveVerification = (username) => {
      setEmail(username);
    };

    const username = qs.parse(authParam, {
      ignoreQueryPrefix: true,
      decoder: (c) => c
    }).email;

    if (authParam && username) {
      saveVerification(username);
    } else {
      setShouldRedirect(true);
    }
    // eslint-disable-next-line
  }, []);

  const callback = {
    error: setError,
    setAuthentication: setAuthenticatedUser
  };

  const onFinish = ({ password, verificationCode }) => {
    cognitoSignUp({ email, verificationCode, password }, callback);
  };

  const layout = {
    labelCol: { span: 6 },
    wrapperCol: { span: 24 }
  };

  const tailLayout = {
    wrapperCol: { offset: 6, span: 24 }
  };

  const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[\^$*.\]{}?\-“!@#%&/,><:;|_~`])\S{8,99}$/;

  return (
    <div className="sign_in_client_container">
      {shouldRedirect && <Redirect to="/signin" />}
      <div className="white_bg_container">
        <div className="ca_logo_container">
          <div className="image_wrapper">
            <img src={CALogo} alt="Constellation Agency Logo" />
          </div>
        </div>
        <div className="form_container">
          <Form
            {...layout}
            name="basic"
            initialValues={{
              remember: true
            }}
            onFinish={onFinish}
          >
            <Form.Item
              label="Your Verification Code"
              name="verificationCode"
              rules={[
                {
                  required: true,
                  message: 'Please input your verification code.'
                }
              ]}
            >
              <Input.Password />
            </Form.Item>
            <Form.Item
              label="Your New Password"
              name="password"
              hasFeedback
              rules={[
                {
                  required: true,
                  message: 'Please input your new password.'
                },
                ({ getFieldValue }) => ({
                  validator(rule, value) {
                    if (value.match(passwordRegex)) {
                      return Promise.resolve();
                    }
                    setIsValidPassword(false);
                    return Promise.reject("Password doesn't meet requirements");
                  }
                })
              ]}
            >
              <Input.Password />
            </Form.Item>
            <Form.Item
              name="confirm"
              label="Confirm Password"
              dependencies={['password']}
              hasFeedback
              rules={[
                {
                  required: true,
                  message: 'Please confirm your password!'
                },
                ({ getFieldValue }) => ({
                  validator(rule, value) {
                    if (!value || getFieldValue('password') === value) {
                      setIsValidPassword(true);
                      return Promise.resolve();
                    }
                    setIsValidPassword(false);
                    return Promise.reject(
                      'The two passwords that you entered do not match!'
                    );
                  }
                })
              ]}
            >
              <Input.Password />
            </Form.Item>
            <Form.Item {...tailLayout}>
              <Button
                type="primary"
                htmlType="submit"
                disabled={!isValidPassword}
              >
                Submit
              </Button>
            </Form.Item>
          </Form>
          {error && <div className="error_msg">{error}</div>}
          <div className="first_time_msg">
            Your password should have at least one special character, lower and
            upper case letters, and be at least 8 characters long.
          </div>
        </div>
      </div>
    </div>
  );
};

export default VerifyClient;
