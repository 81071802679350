import React, { useState, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import qs from 'qs';
import CALogo from '../../images/ca_logo.png';
import { Form, Input, Button } from 'antd';
import {
  cognitoSignInPasswordless,
  answerCustomChallenge
} from '../../utils/auth/client-auth/cognito';
import Loader from '../../components/shared/Loader';

import './SignInClient.scss';

const SignInPasswordless = ({ setAuthenticatedUser }) => {
  const [error, setError] = useState('');
  const authParam = useLocation().search;
  const [isAuthLoading, setIsAuthLoading] = useState(false);
  const [disableBtn, setDisableBtn] = useState(false);

  const callback = {
    error: setError,
    setAuthentication: setAuthenticatedUser,
    setIsAuthLoading: setIsAuthLoading
  };

  useEffect(() => {
    const authenticateUser = async () => {
      setIsAuthLoading(true);
      const authCode = qs.parse(authParam, { ignoreQueryPrefix: true })
        .authCode;

      await answerCustomChallenge(authCode, callback);
    };
    if (authParam && authParam.startsWith('?authCode')) {
      authenticateUser();
    }
    // eslint-disable-next-line
  }, [authParam]);

  const onFinishFirstChallenge = ({ email }) => {
    setDisableBtn(true);
    setTimeout(() => setDisableBtn(false), 30000); // enable btn after 30 secs
    cognitoSignInPasswordless(email, callback);
  };

  const layout = {
    labelCol: { span: 4 },
    wrapperCol: { span: 24 }
  };

  const tailLayout = {
    wrapperCol: { offset: 4, span: 24 }
  };

  return (
    <div className="sign_in_client_container">
      <div className="white_bg_container">
        <div className="ca_logo_container">
          <div className="image_wrapper">
            <img src={CALogo} alt="Constellation Agency Logo" />
          </div>
        </div>
        {isAuthLoading ? (
          <div className="form_container">
            <div className="first_time_msg">Validating authentication...</div>
            <Loader />
          </div>
        ) : (
          <div className="form_container">
            <div className="first_time_msg">
              If your email is in our databases, you will receive an
              authentication link.
            </div>
            <div className="first_time_msg">
              You'll have 3 minutes to use that link before it expires.
            </div>
            <br />
            <Form
              {...layout}
              name="basic"
              initialValues={{
                remember: true
              }}
              onFinish={onFinishFirstChallenge}
            >
              <Form.Item
                label="Email"
                name="email"
                rules={[
                  {
                    required: true,
                    message: 'Email required.'
                  }
                ]}
              >
                <Input />
              </Form.Item>
              <Form.Item {...tailLayout}>
                <Button type="primary" htmlType="submit" disabled={disableBtn}>
                  Submit
                </Button>
                <Link to="/signin">
                  <Button style={{ marginLeft: '10px' }}>
                    Back to regular login
                  </Button>
                </Link>
              </Form.Item>
            </Form>
            {error && <div className="error_msg">{error}</div>}
          </div>
        )}
      </div>
    </div>
  );
};

export default SignInPasswordless;
